// src/utils/api.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
console.log(API_URL);

const api = {
  request: (method, endpoint, data = null, params = null) => {
    const token = localStorage.getItem('token');
    return axios({
      method,
      url: `${API_URL}${endpoint}`,
      data,
      params,
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    });
  },
  login: (credentials) => axios.post(`${API_URL}/auth/login`, credentials),
  register: (data) => axios.post(`${API_URL}/auth/register`, data),
};

export default api;
