import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import socket from "../../utils/socket";
import Cookies from "js-cookie";
import api from "../../utils/api";


const useGameLogic = () => {
  const { id: roomId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [roomName, setRoomName] = useState("");
  const [hasAnswered, setHasAnswered] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [playerAnswer, setPlayerAnswer] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [finalScores, setFinalScores] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [jokerCounts, setJokerCounts] = useState({
    skip: 1,
    double_points: 1,
    hint: 1,
  });
  const [score, setScore] = useState(0);
  const [isCreator, setIsCreator] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [progress, setProgress] = useState(100);
  const [duration, setDuration] = useState(0);
  const [userId, setUserId] = useState(null);
  const [allPlayersAnswered, setAllPlayersAnswered] = useState(false);
  const [playerAnswers, setPlayerAnswers] = useState([]);
  const [nbQuestions, setNbQuestions] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const interval = useRef(null);

  function setTimer(duration) {
    clearInterval(interval.current);
    setTimeLeft(duration); 
    setDuration(duration);
    setProgress(100); // Set progress to 100% initially
    setTimeLeft(duration); // Shorten timer to 3 seconds
    let startTime = Date.now();
    interval.current = setInterval(() => {
    const elapsedTime =  (Date.now() - startTime) / 1000;
    const newTimeLeft = Math.max(duration - elapsedTime, 0);
    setTimeLeft(Math.ceil(newTimeLeft));
    setProgress((newTimeLeft / duration) * 100);

    if (newTimeLeft <= 0) {
      clearInterval(interval.current);
    }
    }, 1000);
    };
    

  useEffect(() => {
    const savedNickname = Cookies.get("nickname");
    const savedUserId = Cookies.get("userId");
    setUserId(savedUserId);

    if (!savedNickname || !savedUserId) {
      navigate("/");
    } else {
      socket.emit("joinRoom", { roomId, nickname: savedNickname, userId: savedUserId });
    }
  }, [roomId, navigate]);

  useEffect(() => {
    setProgress((timeLeft / duration) * 100);
    console.log("Time left: ", timeLeft);
  }, [timeLeft, duration]);

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const response = await api.request("get", `/api/rooms/${roomId}`);
        setRoomName(response.data.name);
        setGameStarted(response.data.started);
        setNbQuestions(response.data.numberQuestions);
        if (response.data.started) {
          setCurrentQuestion(response.data.question);
          setTimer(response.data.timeLeft);
        }
      } catch (error) {
        console.error("Error fetching room details:", error);
      }
    };

    fetchRoomDetails();
  }, [roomId]);

  useEffect(() => {
    socket.on("questionUpdated", ({ question }) => {
      setCurrentQuestion(question);
      setPlayerAnswers(null);
      setHasAnswered(false);
      setTimeLeft(null);
      setPlayerAnswer(null);
      setCorrectAnswer(null);
      setQuestionIndex(question.index+1);
    });

    socket.on("allPlayersAnswered", () => {
      setAllPlayersAnswered(true);
      clearInterval(interval.current);
      setTimeLeft(3); // Shorten timer to 3 seconds
      let startTime = Date.now();
      interval.current = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
        const newTimeLeft = Math.max(3000 - elapsedTime, 0);
        setTimeLeft(Math.ceil(newTimeLeft / 1000));
        setProgress((newTimeLeft / 3000) * 100);

        if (newTimeLeft <= 0) {
          clearInterval(interval.current);
        }
      }, 1000);
    });

    socket.on("startAnswerTimer", ({ duration }) => {
      setTimeLeft(duration / 1000);
      setDuration(duration / 1000);

      const totalMilliseconds = duration;
      let startTime = Date.now();
      interval.current = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const newTimeLeft = Math.max(totalMilliseconds - elapsedTime, 0);
        setTimeLeft(Math.ceil(newTimeLeft / 1000));
        setProgress((newTimeLeft / totalMilliseconds) * 100);

        if (newTimeLeft <= 0) {
          clearInterval(interval.current);
        }
      }, 1000);

      
    });

    socket.on("gameEnded", (data) => {
      setFinalScores(data.finalScores);
      setGameOver(true);
    });

    socket.on("scoreUpdated", ({ score }) => {
      setScore(score);
    });

    socket.on("isCreator", (isCreator) => {
        setIsCreator(true);
    });

    socket.on("gameStarted", () => {
    setGameStarted(true); // Mark game as started
    console.log("Game started");
    });

    socket.on("error", ({ message }) => {
        alert(`Error: ${message}`);
        navigate("/");
      });

    // Handle answer feedback after time is up
    socket.on("answerFeedback", (results) => {
    // const playerFeedback = results.find((r) => r.playerId === userId);
    const correctAnswer = results[0].correctAnswer;
    if (correctAnswer) {
        setCorrectAnswer(correctAnswer); // Store the correct answer
        setPlayerAnswers(results);

    }
    });

    return () => {
      socket.off("questionUpdated");
      socket.off("startAnswerTimer");
      socket.off("gameEnded");
      socket.off("scoreUpdated");
      socket.off("gameStarted");
      socket.off("isCreator");
      socket.off("error");
      socket.off("answerFeedback");
      socket.off("allPlayersAnswered");
    };
  }, [duration, roomId, navigate, questionIndex]);

  const handleStartGame = () => {
    socket.emit("startGame", { roomId, userId: Cookies.get("userId") });
  };

  const handleAnswer = (selectedOption) => {
    if (!hasAnswered) {
      socket.emit("answer", { roomId, userId, selectedOption });
      setPlayerAnswer(selectedOption);
      setHasAnswered(true);
    }
  };

  const getButtonClass = (option) => {
    if (correctAnswer) {
      if (option === correctAnswer) return "is-success";
      if (option === playerAnswer) return "is-danger";
      return "";
    }
    return playerAnswer === option ? "is-info" : "";
  };

  const handleUseJoker = (jokerType) => {
    if (jokerCounts[jokerType] > 0) {
      socket.emit("useJoker", { roomId, jokerType });
    }
  };
  

  return {
    roomName,
    score,
    currentQuestion,
    timeLeft,
    progress,
    gameStarted,
    gameOver,
    finalScores,
    isCreator,
    handleStartGame,
    handleAnswer,
    getButtonClass,
    jokerCounts,
    handleUseJoker,
    correctAnswer,
    playerAnswer,
    playerAnswers,
    nbQuestions,
    questionIndex
  };
};

export default useGameLogic;
