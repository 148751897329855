import React, { useState, useEffect } from 'react';
import ManageThemes from './ManageThemes';
import api from '../../utils/api';

function QuestionForm({ onQuestionSaved, editingQuestion, themes }) {
  const [theme, setTheme] = useState('');
  const [difficulty, setDifficulty] = useState('easy');
  const [translations, setTranslations] = useState([{ language: 'en', questionText: '', options: ['', ''], correctAnswer: '' }]);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (editingQuestion) {
      setEditingQuestionId(editingQuestion._id);
      setTheme(editingQuestion.theme);
      setDifficulty(editingQuestion.difficulty);
      setTranslations(editingQuestion.translations);
      setImage(editingQuestion.image);
    }
  }, [editingQuestion]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await api.request("post", "/api/upload", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setImage(response.data.imageUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleSaveQuestion = async () => {
    try {
      const newQuestion = { theme, difficulty, translations, image  };

      if (editingQuestionId) {
        await api.request("put", `/api/questions/${editingQuestionId}`, newQuestion);
      } else {
        await api.request("post", `/api/questions`, newQuestion);
      }

      resetForm();
      onQuestionSaved();
    } catch (error) {
      console.error('Error saving question:', error);
    }
  };

  const handleAddTranslation = () => {
    setTranslations([...translations, { language: '', questionText: '', options: ['', ''], correctAnswer: '' }]);
  };

  const handleTranslationChange = (index, field, value) => {
    const newTranslations = [...translations];
    if (field === 'options') {
      newTranslations[index].options = value;
    } else {
      newTranslations[index][field] = value;
    }
    setTranslations(newTranslations);
  };

  const handleAddOption = (index) => {
    const newTranslations = [...translations];
    newTranslations[index].options.push('');
    setTranslations(newTranslations);
  };

  const handleRemoveOption = (index, optIndex) => {
    const newTranslations = [...translations];
    if (newTranslations[index].options.length > 2) {
      newTranslations[index].options.splice(optIndex, 1);
      // Update the correct answer if it's removed
      if (newTranslations[index].correctAnswer === newTranslations[index].options[optIndex]) {
        newTranslations[index].correctAnswer = '';
      }
      setTranslations(newTranslations);
    }
  };

  const resetForm = () => {
    setEditingQuestionId(null);
    setTheme('');
    setDifficulty('easy');
    setTranslations([{ language: 'en', questionText: '', options: ['', ''], correctAnswer: '' }]);
    setImage(null);
  };

  return (
    <div className="box">
      <h2 className="title">{editingQuestionId ? 'Edit Question' : 'Add Question'}</h2>

      <ManageThemes onThemeAdded={onQuestionSaved} />

      <div className="field">
        <label className="label">Theme</label>
        <div className="control">
          <div className="select is-fullwidth">
            <select value={theme} onChange={(e) => setTheme(e.target.value)}>
              <option value="">Select Theme</option>
              {themes.map((theme) => (
                <option key={theme._id} value={theme.name}>{theme.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label">Difficulty</label>
        <div className="control">
          <div className="select is-fullwidth">
            <select value={difficulty} onChange={(e) => setDifficulty(e.target.value)}>
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
            </select>
          </div>
        </div>
      </div>

       {/* Image Upload */}
       <div className="field">
        <label className="label">Upload Image</label>
        <div className="control">
          <input type="file" onChange={handleImageChange} />
        </div>
        {image && (
          <div className="control">
            <img src={image} alt="Uploaded" style={{ width: '200px', marginTop: '10px' }} />
          </div>
        )}
      </div>

      {translations.map((translation, index) => (
        <div key={index} className="field">
          <label className="label">Translation {index + 1}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Language"
              value={translation.language}
              onChange={(e) => handleTranslationChange(index, 'language', e.target.value)}
            />
          </div>

          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Question Text"
              value={translation.questionText}
              onChange={(e) => handleTranslationChange(index, 'questionText', e.target.value)}
            />
          </div>

          {translation.options.map((option, optIndex) => (
            <div key={optIndex} className="control is-flex is-align-items-center">
              <input
                className="input"
                type="text"
                placeholder={`Option ${optIndex + 1}`}
                value={option}
                onChange={(e) => {
                  const newOptions = [...translation.options];
                  newOptions[optIndex] = e.target.value;
                  handleTranslationChange(index, 'options', newOptions);
                }}
              />
              <button
                className="button is-danger is-small ml-2"
                onClick={() => handleRemoveOption(index, optIndex)}
                disabled={translation.options.length <= 2}
              >
                Remove
              </button>
            </div>
          ))}

          <div className="control">
            <button className="button is-link is-small" onClick={() => handleAddOption(index)}>
              Add Option
            </button>
          </div>

          <div className="field">
            <label className="label">Correct Answer</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  value={translation.correctAnswer}
                  onChange={(e) => handleTranslationChange(index, 'correctAnswer', e.target.value)}
                >
                  <option value="">Select Correct Answer</option>
                  {translation.options.map((option, optIndex) => (
                    <option key={optIndex} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      ))}

      

      <button className="button is-primary" onClick={handleAddTranslation}>Add Translation</button>
      <button className="button is-link" onClick={handleSaveQuestion}>
        {editingQuestionId ? 'Update Question' : 'Add Question'}
      </button>
    </div>
  );
}

export default QuestionForm;
