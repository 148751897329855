import React from "react";

const MultipleChoiceQuestion = ({ options, handleAnswer, getButtonClass, hasAnswered }) => {
  return (
    <ul className="list content">
      {options.map((option, index) => (
        <li key={index}>
          <button
            className={`button is-fullwidth ${getButtonClass(option)}`}
            disabled={hasAnswered}
            onClick={() => handleAnswer(option)}
          >
            {option}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default MultipleChoiceQuestion;
