import React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; // Import js-cookie

function Navbar() {
  const navigate = useNavigate();
  const nickname = Cookies.get('nickname'); // Retrieve the nickname from the cookie

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <a className="navbar-item">
          <h1 className="title is-5" onClick={() => navigate('/')}><img src="/Quizzy_text_white@512px.png"></img></h1>
        </a>
        {nickname && (
            <div className="navbar-item">
              <p className="subtitle is-6">Hello, {nickname}!</p>
            </div>
          )}
      </div>
      

      <div className="navbar-menu">
        <div className="navbar-end">
          
          <div className="navbar-item">
            <button className="button is-primary" onClick={() => navigate('/')}>
              Back to Lobby
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;