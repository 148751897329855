import React, { useState } from 'react';

function QuestionList({ questions, onEditQuestion, onDeleteQuestion, themes=[] }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [filterTheme, setFilterTheme] = useState('');
  const [filterDifficulty, setFilterDifficulty] = useState('');
  

  // Filtered Questions
  const filteredQuestions = questions.filter((question) => {
    return (
      (filterTheme === '' || question.theme === filterTheme) &&
      (filterDifficulty === '' || question.difficulty === filterDifficulty)
    );
  });

  // Pagination logic
  const indexOfLastQuestion = currentPage * itemsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - itemsPerPage;
  const currentQuestions = filteredQuestions.slice(indexOfFirstQuestion, indexOfLastQuestion);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="box">
      <h3 className="subtitle">Filter Questions</h3>

      <div className="field">
        <label className="label">Filter by Theme</label>
        <div className="control">
          <div className="select is-fullwidth">
            <select value={filterTheme} onChange={(e) => setFilterTheme(e.target.value)}>
              <option value="">All Themes</option>
              {themes.map((theme) => (
                <option key={theme._id} value={theme.name}>{theme.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label">Filter by Difficulty</label>
        <div className="control">
          <div className="select is-fullwidth">
            <select value={filterDifficulty} onChange={(e) => setFilterDifficulty(e.target.value)}>
              <option value="">All Difficulties</option>
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
            </select>
          </div>
        </div>
      </div>

      <ul>
        {currentQuestions.map((question) => (
          <li key={question._id} className="box">
            <p>Theme: {question.theme}, Difficulty: {question.difficulty}</p>
            {question.translations.map((translation, tIndex) => (
              <p key={tIndex}>[{translation.language}] {translation.questionText}</p>
            ))}
            <button className="button is-warning" onClick={() => onEditQuestion(question)}>Edit</button>
            <button className="button is-danger" onClick={() => onDeleteQuestion(question._id)}>Delete</button>
          </li>
        ))}
      </ul>

      <nav className="pagination is-centered" role="navigation" aria-label="pagination">
        <button className="pagination-previous" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
        <button className="pagination-next" onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filteredQuestions.length / itemsPerPage)}>Next</button>
      </nav>
    </div>
  );
}

export default QuestionList;
