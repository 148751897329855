import React, { useState, useEffect } from 'react';
import './Leaderboard.css'; // Import the CSS for animations
import socket from '../../utils/socket';

function Leaderboard({ finalScores, playerAnswers }) {
  const [players, setPlayers] = useState([]);
  const [previousScores, setPreviousScores] = useState({}); // Track previous scores of players
  const [lastUpdatedPlayers, setLastUpdatedPlayers] = useState({}); // Track score changes for popup
  const [rankChanges, setRankChanges] = useState({}); // Track changes in rank for each player

  useEffect(() => {
    // If finalScores is provided, use it to set players state
    if (finalScores && finalScores.length > 0) {
      setPlayers(finalScores);
      return; // Exit useEffect, no need to listen to the socket
    }

    // Otherwise, listen for real-time updates from the socket
    socket.on('leaderboardUpdated', (playerList) => {
      const sortedPlayers = [...playerList].sort((a, b) => b.score - a.score);

      // Determine rank changes only if there's a difference
      const newRankChanges = {};
      const newScoreUpdates = {};
      sortedPlayers.forEach((player, index) => {
        const previousIndex = players.findIndex((p) => p.username === player.username);
        if (previousIndex !== -1 && previousIndex !== index) {
          newRankChanges[player.username] = previousIndex > index ? 'up' : 'down';
        }

        // Compare scores to calculate score changes
        const previousScore = previousScores[player.username] || 0;
        const scoreDifference = player.score - previousScore;
        if (scoreDifference !== 0) {
          newScoreUpdates[player.username] = scoreDifference;
        }
      });

      setRankChanges(newRankChanges);
      setLastUpdatedPlayers(newScoreUpdates);
      setPlayers(sortedPlayers);

      // Update previous scores after leaderboard is updated
      const updatedScores = {};
      sortedPlayers.forEach(player => {
        updatedScores[player.username] = player.score;
      });
      setPreviousScores(updatedScores);

      // Clear rank changes after the animation ends
      setTimeout(() => {
        setRankChanges({});
        setLastUpdatedPlayers({});
      }, 3000); // Match this duration with the CSS animation duration for score pop-ups
    });

    return () => {
      socket.off('leaderboardUpdated');
    };
  }, [players, previousScores, finalScores]);

  return (
    <div className="leaderboard">
      <h2 className="subtitle">Players</h2>
      <ul>
        {players.map((player, index) => {
          playerAnswers = playerAnswers || [];
          const playerAnswer = playerAnswers.find(answer => answer.username === player.username);
          // Apply conditional classes based on rank change
          const rankChangeClass = rankChanges[player.username]
            ? rankChanges[player.username] === 'up'
              ? 'rank-up'
              : 'rank-down'
            : '';

          return (
            <li
  key={player.username}
  className={`leaderboard-item ${rankChangeClass}`}
  style={{ animationDelay: `${index * 0.1}s` }}
>
  <div className="leaderboard-player-container">
  <span className="leaderboard-score">{player.score}</span>
    <div className="leaderboard-player">
      <span className="leaderboard-name">{player.username}</span>
      {playerAnswer && (
        <div className={`speech-bubble ${playerAnswer.correct ? 'is-correct' : 'is-incorrect'}`}>
          {playerAnswer.selectedOption || '...'} 
        </div>
      )}
      {lastUpdatedPlayers[player.username] && (
        <span
          className={`score-popup ${
            lastUpdatedPlayers[player.username] > 0 ? 'score-gain' : 'score-loss'
          }`}
        >
          {lastUpdatedPlayers[player.username] > 0
            ? `+${lastUpdatedPlayers[player.username]}`
            : `${lastUpdatedPlayers[player.username]}`}
        </span>
      )}
    </div>
    
  </div>
</li>
          );
        })}
      </ul>
    </div>
  );
}

export default Leaderboard;
