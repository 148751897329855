import React, { useEffect, useState } from "react";
import "./QuestionDisplay.css";
import QuestionInput from "./QuestionInput";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import Timer from "./Timer";
const QuestionDisplay = ({ currentQuestion, handleAnswer, getButtonClass, hasAnswered, correctAnswer, playerAnswers, timeLeft, progress}) => {
  const imageUrl = currentQuestion && currentQuestion.image ? `${process.env.REACT_APP_API_URL}${currentQuestion.image}` : null;
  const [imageStyle, setImageStyle] = useState({});
  
  const getDifficultyTag = (difficulty) => {
    switch (difficulty) {
      case "easy":
        return <span className="tag is-success">Easy</span>;
      case "medium":
        return <span className="tag is-warning">Medium</span>;
      case "hard":
        return <span className="tag is-danger">Hard</span>;
      default:
        return null; // No tag if difficulty is not defined
    }
  };


  function applyImageEffects(effects) {
    if (effects) {
      let newStyle = {};
      console.log("effects", effects);
  
      // Check if the "dezoom" effect is applied
      if (effects.includes("dezoom")) {
        newStyle.animation = "zoomOut 15s ease-out"; // Apply the dezoom animation
      }
  
      // Check if the "dark" effect is applied
      if (effects.includes("dark")) {
        newStyle.filter = "brightness(0.0)"; // Apply dark effect by reducing brightness
      }
  
      // Apply the new styles
      setImageStyle(
      newStyle,  // Add the new styles
      );
      
      return effects;
    }
  }

  useEffect(() => {
    if (currentQuestion && !correctAnswer) {
      // Apply the transformation effect
      applyImageEffects(currentQuestion.effects);
    } else if (currentQuestion && correctAnswer) {
      // Reset the transformation (no transformation applied)
      setImageStyle({
        // filter: 'brightness(1)', // Reset to original
        transition: 'filter 2s ease',
      });
    }
  }, [currentQuestion, correctAnswer]);



  return (
    <div className="content mb-5">
      {/* <h3 className="subtitle is-6 has-text-centered">Current Question:</h3> */}
      {currentQuestion ? (
        <div>
          <p style={{marginBottom: "10px"}}className="title is-4 has-text-weight-normal has-text-centered">{currentQuestion.difficulty && (getDifficultyTag(currentQuestion.difficulty))} {currentQuestion.questionText}</p>

          {/* Display Image if Provided */}
          {imageUrl && (
            <div className="is-flex is-justify-content-center question-image-container" style={{ marginBottom: "10px" }}>
              <img className="question-image"
                src={imageUrl}
                alt="Related to the question"
                style={{ maxWidth: "100%", height: "auto", marginBottom: "0", ...imageStyle}}
              />
            </div>
          )}

          <Timer timeLeft={timeLeft} progress={progress} />

          {/* Render Multiple Choice or Input Based on Round Type */}
          {currentQuestion.type === "input-based"  ? (
            
            <QuestionInput currentQuestion={currentQuestion} handleAnswer={handleAnswer} hasAnswered={hasAnswered} />
        
            
          ) : (
            <MultipleChoiceQuestion
              options={currentQuestion.options}
              handleAnswer={handleAnswer}
              getButtonClass={getButtonClass}
              hasAnswered={hasAnswered}
            />
          )}

          {/* Display Correct Answer After Feedback */ console.log("correct", correctAnswer)}
          {correctAnswer && currentQuestion.type === "input-based" &&  (
            <div className="has-text-centered mt-4">
              <p className="has-text-success">
                Correct Answer: {correctAnswer}
              </p>
            </div>
          )}


    


        </div>
      ) : (
        <p>Waiting for question...</p>
      )}
    </div>
  );
};

export default QuestionDisplay;
